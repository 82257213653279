import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Select, Table, notification } from 'antd';
import { DateRangePicker } from 'components';
import {
    downloadReceipt,
    registerCashOrderInCashdesk,
    registerServiceInputCashOrderInCashdesk,
    registerServiceOutputCashOrderInCashdesk,
    sendEmailWithReceipt,
    sendSmsWithReceipt
} from 'core/cash/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PrintReceiptModal from './PrintReceiptModal';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {
    sendEmailWithReceipt,
    sendSmsWithReceipt,
    downloadReceipt,
    registerCashOrderInCashdesk,
    registerServiceInputCashOrderInCashdesk,
    registerServiceOutputCashOrderInCashdesk
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export class CashOrdersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: {},
            printReceiptOpen: false
        };
    }

    _setCashOrderEntity = cashOrderEntity => this.setState({ cashOrderEntity });

    /**
     * Called when user want to send receipt on client's email, email is taken from client for which cash order was created.
     * We can send emails for RST cashOrders only
     * @param {*} param.cashOrderId - cash order to generate email from(contains data about its RST cashbox and client)
     * @returns
     */
    onSendEmail = ({ cashOrderId }) => {
        const {
            sendEmailWithReceipt,
            intl: { formatMessage }
        } = this.props;

        if (!cashOrderId) return;

        sendEmailWithReceipt({ cashOrderId });
        notification.success({
            message: formatMessage({ id: 'sent' })
        });
    };

    /**
     *  When user want to send receipt on client's modile via sms, phone number is taken from client for which cash order was created.
     * We can send sms for RST cashOrders only.
     * @param {*} param.cashOrderId - cash order to generate email from(contains data about its RST cashbox and client)
     * @returns
     */
    onSendSms = ({ cashOrderId }) => {
        const {
            sendSmsWithReceipt,
            intl: { formatMessage }
        } = this.props;

        if (!cashOrderId) return;

        sendSmsWithReceipt({ cashOrderId });
        notification.success({
            message: formatMessage({ id: 'sent' })
        });
    };

    /**
     * This registers specific cash order in cashdesk base on its type and parameters(sale/return/service input/service output/)
     * @param {Object} params.cashOrder contains cashOrderId to register and necessary data about cashOrder
     */
    onRepeatRegistrationInCashdesk = ({ cashOrder }) => {
        const {
            registerCashOrderInCashdesk,
            registerServiceInputCashOrderInCashdesk,
            registerServiceOutputCashOrderInCashdesk
        } = this.props;
        try {
            if (cashOrder.rst && cashOrder.clientId && cashOrder.type != 'EXPENSE') {
                // Sale or return contains client and is applied to RST cashboxes
                // repeat registration
                registerCashOrderInCashdesk(cashOrder.id);
            } else if (cashOrder.otherCounterparty && cashOrder.type == 'INCOME') {
                // repeat service input
                registerServiceInputCashOrderInCashdesk({ cashOrderId: cashOrder.id });
            } else if (cashOrder.otherCounterparty && cashOrder.type == 'EXPENSE') {
                // repeat service output
                registerServiceOutputCashOrderInCashdesk({ cashOrderId: cashOrder.id });
            } else {
                // Error
                notification.error({
                    message: 'Error',
                    description: `
                    Invalid type of cashOrder, it cannot be registred in
                    cashdesk because it was not detected as Service input,
                    Service output, Sale or Return
                `
                });
            }
        } catch (error) {
            notification.error({
                message: this.props.intl.formatMessage({ id: 'document_id_required' })
            });
        }
    };

    filterDataSource = dataSource => {
        const { filters, datetimeFrom, datetimeTo, type } = this.state;
        let result = [...dataSource];
        if (datetimeFrom && datetimeTo) {
            result = result.filter(
                row => dayjs(row.datetime).isAfter(datetimeFrom) && dayjs(row.datetime).isBefore(datetimeTo)
            );
        }

        console.log(type, 'type');
        if (Array.isArray(type) && type.length > 0) {
            result = result.filter(row => {
                const field = row.type;

                // Check if any element in `type` array is included in `field`
                return type.some(t => field.includes(t));
            });
        } else if (type) {
            result = result.filter(row => {
                const field = row.type;

                return field.includes(type);
            });
        }
        Object.entries(filters).map(([key, value]) => {
            if (value) {
                result = result.filter(row => {
                    const field = String(_.get(row, key, '')).toLowerCase();

                    return field.includes(value.toLowerCase());
                });
            }
        });

        return result;
    };

    getColumnSearchProps = dataIndexes => {
        return {
            filtered: Boolean(_.get(this.state.filters, dataIndexes, false)),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndexes)}
                        placeholder={this.props.intl.formatMessage({ id: 'search' })}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        value={selectedKeys[0]}
                    />

                    <Button
                        icon={<SearchOutlined />}
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndexes)}
                        size='small'
                        style={{ width: 90, marginRight: 8 }}
                        type='primary'
                    >
                        <FormattedMessage id='search' />
                    </Button>
                    <Button
                        onClick={() => this.handleReset(dataIndexes, clearFilters)}
                        size='small'
                        style={{ width: 90 }}
                    >
                        <FormattedMessage id='reset' />
                    </Button>
                </div>
            ),
            filterIcon: filtered => <FilterOutlined style={{ color: filtered ? 'var(--primary)' : undefined }} />,

            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select());
                }
            }
        };
    };

    getColumnDaterangeProps = () => ({
        filtered: _.get(this.state, 'datetime', false),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <DateRangePicker
                    allowClear
                    dateRange={[this.state.datetimeFrom, this.state.datetimeTo]}
                    maximize
                    onDateChange={([startDate, endDate]) => {
                        this.setState({ datetimeFrom: startDate, datetimeTo: endDate });
                    }}
                />
            </div>
        ),
        filterIcon: () => <FilterOutlined style={{ color: this.state.datetimeFrom ? 'var(--primary)' : undefined }} />
    });

    getColumnTypeProps = () => ({
        filtered: _.get(this.state, 'type', false),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Select
                    allowClear
                    mode='multiple'
                    onChange={value => {
                        this.setState({ type: value });
                    }}
                    optionFilterProp='children'
                    placeholder={this.props.intl.formatMessage({
                        id: 'search'
                    })}
                    showSearch
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                    value={this.state.type}
                >
                    <Option key='INCOME' value='INCOME'>
                        <FormattedMessage id='cash-order-form.type.INCOME' />
                    </Option>
                    <Option key='EXPENSE' value='EXPENSE'>
                        <FormattedMessage id='cash-order-form.type.EXPENSE' />
                    </Option>
                    <Option key='ADJUSTMENT_INCOME' value='ADJUSTMENT_INCOME'>
                        <FormattedMessage id='cash-order-form.type.ADJUSTMENT_INCOME' />
                    </Option>
                    <Option key='ADJUSTMENT_EXPENSE' value='ADJUSTMENT_EXPENSE'>
                        <FormattedMessage id='cash-order-form.type.ADJUSTMENT_EXPENSE' />
                    </Option>
                </Select>
            </div>
        ),
        filterIcon: () => <FilterOutlined style={{ color: this.state.datetimeFrom ? 'var(--primary)' : undefined }} />
    });

    handleSearch = (selectedKeys, confirm, dataIndexes) => {
        this.state.filters[`${dataIndexes}`] = selectedKeys[0];
        this.setState({});
    };

    handleReset = (dataIndexes, clearFilters) => {
        clearFilters();
        delete this.state.filters[`${dataIndexes}`];
        this.setState({});
    };

    handlePrintReceiptOpen = cashOrder => {
        this.setState({ printReceiptOpen: true, selectedPrintCashOrder: cashOrder });
    };

    handlePrintReceiptClose = () => this.setState({ printReceiptOpen: false });

    handlePrintOk = () => {
        this.props.downloadReceipt({ cashOrderId: this.state.selectedPrintCashOrder.id });
    };

    render() {
        const { cashOrders, cashOrdersFetching, openPrint, openEdit, isMobile, downloadReceipt } = this.props;

        const { printReceiptOpen, selectedPrintCashOrder } = this.state;

        this.columns = columnsConfig({
            handlePrintReceiptOpen: this.handlePrintReceiptOpen,
            onRepeatRegistrationInCashdesk: this.onRepeatRegistrationInCashdesk,
            downloadReceipt,
            openPrint,
            openEdit,
            isMobile,
            onSendEmail: this.onSendEmail,
            onSendSms: this.onSendSms,
            user: this.props.user,
            getColumnSearchProps: this.getColumnSearchProps,
            getColumnDaterangeProps: this.getColumnDaterangeProps,
            getColumnTypeProps: this.getColumnTypeProps
        });

        const pagination = {
            pageSize: this.props.filters.pageSize,
            size: 'large',
            total: Math.ceil(this.props.totalCount / this.props.filters.pageSize) * this.props.filters.pageSize,

            current: this.props.filters.page,
            onChange: (page, pageSize) => {
                this.props.setCashOrdersPage({ page, pageSize });
                this.props.fetchCashOrders({ page, pageSize });
            }
        };

        return (
            <React.Fragment>
                <Table
                    bordered
                    className={Styles.table}
                    columns={this.columns}
                    dataSource={this.filterDataSource(cashOrders)}
                    loading={cashOrdersFetching}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    onRow={(record, rowIndex) => {
                        return {
                            onDoubleClick: event => {
                                openEdit(record);
                            }
                        };
                    }}
                    pagination={pagination}
                    rowClassName={record => {
                        // Change style if cash order was registred with rst(fiscal number) and its registration failed
                        return record.rst && !record.isRegisteredWithRst && record.type != 'EXPENSE'
                            ? Styles.unregisteredCashOrder
                            : void 0;
                    }}
                    rowKey={record => record.id}
                    scroll={!isMobile ? { x: 1000 } : {}}
                    size='small'
                />
                <PrintReceiptModal
                    cashOrder={selectedPrintCashOrder}
                    onClose={this.handlePrintReceiptClose}
                    onOk={this.handlePrintOk}
                    open={printReceiptOpen}
                />
            </React.Fragment>
        );
    }
}
