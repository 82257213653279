import { Table } from 'antd';
import React, { useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';

const TaxesTab = ({ intl: { formatMessage }, ...props }) => {
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);

    const columns = useMemo(() => {
        return [
            {
                title: formatMessage({ id: 'locations.name' })
            },
            {
                title: formatMessage({ id: 'info_modal.value' })
            },
            {
                title: formatMessage({ id: 'storage.measure_units' })
            },
            {
                title: formatMessage({ id: 'comment' })
            },
            {
                title: formatMessage({ id: 'Реквізити для сплати податку' }),
                children: [
                    {
                        title: formatMessage({ id: 'Отримувач' })
                    },
                    {
                        title: formatMessage({ id: 'Код за ЄДРПОУ отримувача' })
                    },
                    {
                        title: formatMessage({ id: 'Банк отримувача' })
                    },
                    {
                        title: formatMessage({ id: 'Номер рахунку (IBAN)' })
                    }
                ]
            }
        ];
    }, [formatMessage]);

    return (
        <React.Fragment>
            <Table
                bordered
                columns={columns}
                dataSource={dataSource}
                loading={loading}
                pagination={false}
                size='small'
            />
        </React.Fragment>
    );
};
export default injectIntl(TaxesTab);
